import React from 'react'
import Logo from '../navbar/Logo'
import NavItems from '../navbar/NavItems'

const Footer = () => {
  return (
    <div className='w-full h-[200px] bg-blue-500 flex items-end max-sm:h-[150px]'>
      <div className='w-full flex items-center justify-around py-4'>
        <div><Logo/></div>

        <div className='max-lg:hidden'><NavItems/></div>

        <div className='text-lg font-bold text-white max-sm:text-sm'>copyright &#169; 2025 <span className=' text-pink-600 cursor-pointer transition duration-200 ease-in hover:underline'>Neeraj</span></div>
      </div>
    </div>
  )
}

export default Footer
