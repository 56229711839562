import React from 'react'
import NavItems from '../navbar/NavItems'

const Footer2 = () => {
  return (
    <div className='w-full flex items-center justify-around py-4 bg-blue-500'>
        <div className='max-md:hidden'><NavItems/></div>
        <div className='text-lg font-bold text-white'>copyright &#169; 2025 <span className=' text-pink-600'>Neeraj</span></div>
    </div>
  )
}

export default Footer2
